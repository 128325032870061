[data-component='SettingsModal'].settings-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.settings-modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  min-width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  h2 {
    margin-top: 0;
  }

  button {
    margin-top: 16px;
    cursor: pointer;
  }
}